import type { MediaFragment, ResponsiveMediaFragment } from "@/generated/client.generated";
import { classNames } from "@/utils/styling-utils";
import type { ButtonType } from "../base/button";
import { Heading } from "../base/heading";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { Text } from "../ui/text";
import { LinkButton } from "./link-button";
import { Media } from "./media";
import type { TextTheme } from "./ui.model";

export type MediaCardHorizontalAlignmentType = "left" | "center" | "right";
export type MediaCardVerticalAlignmentType = "top" | "center" | "bottom" | "baseline";

interface MediaCardProps {
  alignContentHorizontally?: MediaCardHorizontalAlignmentType;
  alignContentVertically?: MediaCardVerticalAlignmentType;
  controls?: boolean;
  blockMargin?: boolean;
  buttonText?: string;
  buttonType?: ButtonType;
  fallbackAlt: string; // This alt text will only be used if the image from Strapi lacks an alternative text. The fallback will give context of the image
  heading?: string;
  headingSize?: "small";
  isSlider?: boolean;
  media: MediaFragment | ResponsiveMediaFragment;
  priority?: boolean; // specific for Image
  text?: string;
  textTheme?: TextTheme; // light er default
  url?: string;
}

export const MediaCard = ({
  alignContentHorizontally,
  alignContentVertically,
  controls,
  blockMargin,
  buttonText,
  buttonType,
  fallbackAlt,
  heading,
  headingSize,
  isSlider,
  media,
  priority,
  text,
  textTheme,
  url,
}: MediaCardProps) => {
  return (
    <div
      className={classNames(
        "media-card",
        alignContentVertically && `media-card--vertical-align--${alignContentVertically}`,
        textTheme && `media-card--text-theme--${textTheme}`,
        blockMargin && "media-card--block-margin",
      )}
    >
      <Media autoplay={true} fallbackAlt={fallbackAlt} media={media} priority={priority} controls={controls} />

      <div
        className={classNames(
          "media-card__content",
          alignContentHorizontally && `media-card__content--horizontal-align--${alignContentHorizontally}`,
          alignContentVertically && `media-card__content--vertical-align--${alignContentVertically}`,
          headingSize && `media-card__content--heading-size--${headingSize}`,
          isSlider && "media-card__content--slider",
          blockMargin && "media-card__content--block-margin",
        )}
      >
        {heading && <Heading type="h2">{heading}</Heading>}

        {text && (
          <>
            <Text align="center" shadow={true} value={text} />
            <HorizontalSpacer />
          </>
        )}

        {url && buttonText && (
          <LinkButton buttonType={buttonType ?? "primary"} href={url}>
            <Text value={buttonText} />
          </LinkButton>
        )}
      </div>
    </div>
  );
};
