import type { MediaFragment, ResponsiveMediaFragment } from "@/generated/client.generated";
import { useScrollToElement } from "@/hooks/use-scroll-to-element";
import { classNames } from "@/utils/styling-utils";
import { Heading } from "../base/heading";
import { LinkButton } from "./link-button";
import { Markdown } from "./markdown";
import { Media } from "./media";

interface NewsCardProps {
  blockMargin?: boolean;
  buttonText?: string;
  fallbackAlt: string; // This alt text will only be used if the image from Strapi lacks an alternative text. The fallback will give context of the image
  heading: string;
  id?: string;
  media: MediaFragment | ResponsiveMediaFragment;
  priority?: boolean; // specific for Image
  text?: string;
  url?: string;
}

export const NewsCard = ({
  blockMargin,
  buttonText,
  fallbackAlt,
  heading,
  id,
  media,
  priority,
  text,
  url,
}: NewsCardProps) => {
  const scrollTo = useScrollToElement(id);

  return (
    <div className={classNames("news-card", blockMargin && "news-card--block-margin")} id={scrollTo ?? ""}>
      <div className="news-card__heading-and-button">
        <Heading type="h2">{heading}</Heading>

        {url && buttonText && (
          <LinkButton buttonType="secondary" href={url}>
            {buttonText}
          </LinkButton>
        )}
      </div>

      <Media fallbackAlt={fallbackAlt} media={media} priority={priority} />

      {text && (
        <div className="news-card__text">
          <Markdown replaceHeadingLevel1={true}>{text}</Markdown>
        </div>
      )}
    </div>
  );
};
