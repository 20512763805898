import { classNames } from "@/utils/styling-utils";
import type { ReactNode } from "react";

interface FilterWrapperProps {
  children: ReactNode;
  dontSpanFiltersFullWidth?: boolean;
  showSubFilter?: boolean;
}

export const FilterWrapper = ({ children, dontSpanFiltersFullWidth, showSubFilter }: FilterWrapperProps) => (
  <div
    className={classNames(
      "filter-wrapper",
      dontSpanFiltersFullWidth && "filter-wrapper--dont-span-full-width",
      showSubFilter && "filter-wrapper--show-sub-filter",
    )}
  >
    {children}
  </div>
);
