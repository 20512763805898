import { classNames } from "@/utils/styling-utils";
import type { ReactNode } from "react";

interface CardProps {
  bgColor?: boolean;
  horizontalPlacement?: "left" | "center" | "right";
  children: ReactNode;
}

export const Card = ({ bgColor, children, horizontalPlacement }: CardProps) => (
  <div
    className={classNames(
      "card",
      bgColor && "card--background-color",
      horizontalPlacement && `card__horizontal-placement--${horizontalPlacement}`,
    )}
  >
    {children}
  </div>
);
