import { FilterButtonWrapper } from "../filter/filter-button";
import { FilterButtonContainer } from "../filter/filter-button-container";
import { FilterWeekdaysSkeleton } from "../filter/filter-weekdays";
import { FilterWrapper } from "../filter/filter-wrapper";
import { HorizontalContainer } from "../layout/horizontal-container";
import { ButtonSkeleton } from "./button-skeleton";
import { TextSkeleton } from "./text-skeleton";

interface BookingFilterSkeletonProps {
  withWeekdays?: boolean;
}
export const BookingFilterSkeleton = ({ withWeekdays = true }: BookingFilterSkeletonProps) => {
  return (
    <>
      <HorizontalContainer horizontalPlacement="space" verticalPlacement="center" width="full">
        <TextSkeleton size="large" />
      </HorizontalContainer>
      <FilterWrapper dontSpanFiltersFullWidth={false}>
        <HorizontalContainer>
          <FilterButtonContainer>
            <FilterButtonWrapper width={33.33}>
              <ButtonSkeleton />
            </FilterButtonWrapper>
            <FilterButtonWrapper width={33.33}>
              <ButtonSkeleton />
            </FilterButtonWrapper>
            <FilterButtonWrapper width={33.33}>
              <ButtonSkeleton />
            </FilterButtonWrapper>
          </FilterButtonContainer>
        </HorizontalContainer>
        {withWeekdays && (
          <HorizontalContainer width="full" horizontalPlacement="space">
            <FilterWeekdaysSkeleton />
          </HorizontalContainer>
        )}
      </FilterWrapper>
    </>
  );
};
