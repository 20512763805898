import { beginCheckoutEvent, sendEvent } from "@/analytics/event-logger";
import { type OrderFragment, OrderItemTypes, type SubscriptionItem } from "@/generated/client.generated";
import { type FormData, UserJourney } from "./types";

export function sendStepEvent(chosenValue: string, stepNumber: number, stepName?: string, userJourney?: UserJourney) {
  sendEvent({
    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
    ["Bli_medlem"]: {
      [`${stepName ?? ""}`]: {
        step: chosenValue,
        stepNumber: stepNumber,
        type: userJourney === UserJourney.Single ? "Kontant" : "Gjentagende",
      },
    },
    event: `Bli_medlem_${stepName}`,
  });
}

export function sendBookingEvent(
  name: string,
  centerName: string,
  action: "book" | "cancel" | "waitinglist" | "cancel_waitinglist",
) {
  sendEvent({
    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
    ["Booking_gruppetime"]: {
      name: name,
      center: centerName,
      action: action,
    },
    event: "Booking_gruppetime",
  });
}

export async function sendCheckoutEvent(
  formData: FormData,
  subscription: SubscriptionItem | undefined,
  order: OrderFragment,
) {
  const totalValue =
    formData.userJourney === UserJourney.Recurring
      ? (subscription?.recurringPayment?.price.amount ?? 0) * 12
      : (subscription?.price.amount ?? 0);
  const leftToPay = order?.leftToPay.amount ?? 0;
  if (order?.id && subscription) {
    sendStepEvent(
      subscription?.product.name ?? "",
      formData.currentStepIndex + 1,
      subscription?.product.name ?? "",
      formData.userJourney,
    );

    const items = order?.items.map((item) => {
      const isSubscription = item.type === OrderItemTypes.Subscription;
      return {
        accountType: isSubscription ? formData.accountType?.name : undefined,
        customerType: isSubscription ? formData.customerType?.title : undefined,
        discount: (item.discount?.amount ?? 0) / 100,
        isRecurring: formData.userJourney !== UserJourney.Single,
        item_brand: "3T",
        item_id: item.id,
        item_name: item.product.name,
        location_id: isSubscription ? formData.mainCenter?.page.title : undefined,
        mainCenter: isSubscription ? formData.mainCenter?.page.title : undefined,
        price: (item?.fullPrice?.amount ?? item.price.amount) / 100,
        quantity: 1,
      };
    });
    beginCheckoutEvent((leftToPay + totalValue) / 100, items);
  }
}
