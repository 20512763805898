import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { PTInfoOverlay } from "../trainer/pt-info-overlay";
import { ImageSkeleton } from "../ui/image";
import { ButtonSkeleton } from "./button-skeleton";
import { HeadingSkeleton } from "./heading-skeleton";

export function TrainerCardSkeleton() {
  return (
    <div className="trainer-card">
      <ImageSkeleton />

      <PTInfoOverlay>
        <HorizontalSpacer size="small" />
        <HeadingSkeleton type="h2" />
        <HorizontalSpacer />

        <HorizontalContainer horizontalPlacement="center" noWrap={true} width="full">
          <VerticalSpacer />
          <ButtonSkeleton />
          <VerticalSpacer />
        </HorizontalContainer>
        <HorizontalSpacer size="medium" />
      </PTInfoOverlay>
    </div>
  );
}
