import { Image } from "@/components/ui/image";
import type { ImageFragment, TrenerFragment } from "@/generated/client.generated";
import { paths } from "@/utils/paths-utils";
import { roleToHumanString } from "@/utils/pt-utils";
import { classNames } from "@/utils/styling-utils";
import { Button } from "../base/button";
import { Heading } from "../base/heading";
import { Link } from "../base/link";
import { IconGroup } from "../icons/icon-group";
import { MapPinIcon, UserCircleIcon } from "../icons/icons";
import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { PTInfoOverlay } from "../trainer/pt-info-overlay";

interface TrainerCardProps {
  isSlider?: boolean;
  parts: TrenerFragment;
  onlyDisplayTheseRoles?: ReadonlyArray<string>;
}

export const TrainerCard = ({ isSlider, parts, onlyDisplayTheseRoles = [] }: TrainerCardProps) => {
  const centersToDisplay = parts.center?.page.title ? [parts.center?.page.title] : [];
  const rolesToDisplay =
    onlyDisplayTheseRoles.length > 0
      ? parts.roles.filter((role) =>
          onlyDisplayTheseRoles.find(
            (onlyDisplayRole) => role.toLocaleLowerCase() === onlyDisplayRole.toLocaleLowerCase(),
          ),
        )
      : parts.roles;

  return (
    <div className={classNames("trainer-card", isSlider && "trainer-card--slider")}>
      <Link href={paths.trainer(parts.slug)}>
        {parts.page.responsiveMedia?.mobile?.url && (
          <Image
            alt="Bilde av pt"
            src={parts.page.responsiveMedia?.mobile.url}
            placeholder={(parts.page.responsiveMedia.mobile as ImageFragment).placeholder ?? undefined}
          />
        )}

        <PTInfoOverlay>
          <HorizontalSpacer size="small" />
          <Heading type="h2">{parts.page.title}</Heading>

          <HorizontalContainer horizontalPlacement="center" width="full">
            <IconGroup
              textSize="small"
              noWrap={true}
              hideOverflow={true}
              icons={[
                ...centersToDisplay.map((center) => ({
                  icon: <MapPinIcon size="small" />,
                  text: center,
                })),
                ...rolesToDisplay.flatMap((role) => ({
                  icon: <UserCircleIcon size="small" />,
                  text: `${roleToHumanString(role)}`,
                })),
              ]}
            />
          </HorizontalContainer>
          <HorizontalSpacer />

          <HorizontalContainer horizontalPlacement="center" noWrap={true} width="full">
            <VerticalSpacer />
            <Button buttonType="secondary" width="full" wrap={true}>
              Les mer
            </Button>
            <VerticalSpacer />
          </HorizontalContainer>
          <HorizontalSpacer size="medium" />
        </PTInfoOverlay>
      </Link>
    </div>
  );
};
