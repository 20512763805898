"use client";

import { type BlocksContent, BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Heading } from "../base/heading";
import { Link } from "../base/link";
import type { HorizontalAlignmentType } from "../layout/layout.model";
import TextWithChildren from "./text-with-children";
import type { TextTheme } from "./ui.model";

interface RichTextJsonRendererProps {
  readonly content: BlocksContent;
  textTheme: TextTheme;
  alignTextHorizontally: HorizontalAlignmentType;
  truncate?: boolean;
  noWrap?: boolean;
}

export default function RichTextJsonRenderer({ content, textTheme, alignTextHorizontally }: RichTextJsonRendererProps) {
  if (!content) {
    return null;
  }
  const alignClass = alignTextHorizontally === "center" ? "center" : undefined;

  return (
    <BlocksRenderer
      content={content}
      blocks={{
        code: () => {
          // The default rendering of code blocks overrides the page width, creating a mess. We don't need code blocks, and so we will not spend time fixing the rendering
          return <></>;
        },
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
            case 2:
              return <Heading type="h2">{children}</Heading>;
            default:
              return <Heading type="h3">{children}</Heading>;
          }
        },
        image: () => {
          // We don't support images in richtextblock, as it is a block for text only
          return <></>;
        },
        link: ({ children, url }) => {
          return (
            <span className={`rich-text-json-renderer-override__color--${textTheme}`}>
              <Link href={url} newTab={url[0] !== "/"}>
                {children}
              </Link>
            </span>
          );
        },
        // trenger ikke override list ettersom vi kan override list-item. Vår list-komponent har ekstra styling for kolonner, så den passer ikke å bruke her
        "list-item": ({ children }) => {
          return (
            <div className={`rich-text-json-renderer-override__color--${textTheme}`}>
              <li>{children}</li>
            </div>
          );
        },

        // Modifiers are applied on the paragraph, so bold, code, italic, strikethrough and underline will be wrapped in two TextWithChildren components
        paragraph: ({ children }) => {
          return (
            <TextWithChildren align={alignClass} color={textTheme}>
              {children}
            </TextWithChildren>
          );
        },
        // quote: () => { // TODO: Implementer en custom versjon av blockquote som heller skal rendres enn defaulten
        //   return <></>;
        // }
      }}
    />
  );
}
