"use client";
import type { ReactNode } from "react";

import { Image } from "@/components/ui/image";
import { MediaQuery } from "@/contexts/media-context";
import type { MediaFragment, ResponsiveMedia, ResponsiveMediaFragment } from "@/generated/client.generated";
import { useIsDesktop } from "@/hooks/use-is-desktop";
import { classNames } from "@/utils/styling-utils";
import { Link } from "../base/link";
import type { WidthType } from "../layout/layout.model";
import { VideoPlayer } from "./video-player";

interface MediaAllProps {
  openUrlInNewTab?: boolean;
  url?: string;
}

interface MediaProps extends MediaAllProps {
  autoplay?: boolean;
  controls?: boolean;
  fallbackAlt: string;
  gradient?: boolean;
  media: MediaFragment | ResponsiveMediaFragment;
  priority?: boolean; // specific for Image
  width?: WidthType | number;
}

export const Media = ({
  autoplay,
  controls,
  fallbackAlt,
  gradient,
  media,
  openUrlInNewTab,
  priority = false,
  url,
  width,
}: MediaProps) => {
  const isDesktop = useIsDesktop();

  // Transform media of type MediaFragment to ResponsiveMediaFragment
  const responsiveMedia =
    media.__typename !== "ResponsiveMedia" ? ({ desktop: null, mobile: media } as ResponsiveMedia) : media;

  const mediaToDisplay = isDesktop && responsiveMedia.desktop ? responsiveMedia.desktop : responsiveMedia.mobile;

  // fallbackAlt is only used if the alternative text is not set in Strapi for the specific media file
  const alternativeTextToDisplay = mediaToDisplay?.alternativeText ?? fallbackAlt;
  const caption = mediaToDisplay?.caption ?? "";

  // svg-filer må ha en definert bredde for å bli vist i Firefox
  // gjør om bredde satt i pixels i Strapi til rem
  const setWidth = width && width !== "full" ? `${width / 10}rem` : undefined;

  return (
    <div
      className={classNames("media", width === "full" && "media__width--full")}
      style={{
        ...(setWidth && { maxWidth: setWidth, width: "100%" }),
      }}
    >
      <MediaWrapper alt={alternativeTextToDisplay} url={url} openUrlInNewTab={openUrlInNewTab}>
        {mediaToDisplay?.__typename === "Video" ? (
          <>
            <MediaQuery lessThan="lg">
              <VideoPlayer
                autoplay={autoplay}
                caption={caption}
                url={mediaToDisplay?.url}
                thumbnail={responsiveMedia.thumbnailForMobileVideo ?? undefined}
                controls={controls}
              />
            </MediaQuery>

            <MediaQuery greaterThanOrEqual="lg">
              <VideoPlayer
                autoplay={autoplay}
                caption={caption}
                url={mediaToDisplay?.url}
                thumbnail={responsiveMedia.thumbnailForDesktopVideo ?? undefined}
                controls={controls}
              />
            </MediaQuery>
          </>
        ) : (
          responsiveMedia.mobile?.url && (
            <Image
              alt={alternativeTextToDisplay}
              caption={caption}
              gradient={gradient}
              src={responsiveMedia.mobile.url}
              srcDesktop={responsiveMedia.desktop?.url}
              priority={priority}
              placeholder={mediaToDisplay?.placeholder ?? undefined}
            />
          )
        )}
      </MediaWrapper>
    </div>
  );
};

interface MediaWrapperProps extends MediaAllProps {
  alt: string;
  children: ReactNode;
}

const MediaWrapper = ({ children, openUrlInNewTab, url }: MediaWrapperProps) =>
  url ? (
    <Link href={url} newTab={openUrlInNewTab} textDecoration="none">
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
